<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div>
    <b-tabs
      horizontal
      nav-wrapper-class="nav-horizontal"
    >
      <b-card>
          <b-form>
            <validation-observer ref="simpleRules">
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Заголовок"
                      rules="required"
                    >
                      <label for="Product name-default">Заголовок</label>
                      <b-form-input
                        v-model="news.title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Заголовок"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        name="Товары"
                        rules="required"
                    >
                      <label for="Product name-default">Товары</label>
                      <b-form-input
                          v-model="news.product_ids"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Товары"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-col md="12">
                <validation-provider
                    #default="{ errors }"
                    name="Описание"
                    rules="required"
                >
                  <b-card-code title="Описание">
                    <!-- <b-card-text></b-card-text> -->

                    <quill-editor
                        v-model="news.description"
                        :options="editorOption"
                    />
                    <template #code>
                      {{ codeBubble }}
                    </template>
                  </b-card-code>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Фото"
                    rules="required"
                  >
                    <label for="Quantity-default">Фото</label>
                    <div class="dropbox">
                      <input
                        type="file"
                        :name="uploadFieldName"
                        accept="image/*"
                        class="input-file"
                        @change="previewImage"
                      >
                      <p>
                        Drag your file(s) here to begin<br> or click to browse
                      </p>

                    </div>
                      <img
                        height="200px"
                        :src="`${preview || news.photo}`"
                      >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-row>
                <!-- <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Описание"
                    rules="required"
                  >
                    <label for="textarea-default">Описание</label>
                    <b-form-textarea
                      id="textarea-default"
                      v-model="product.description"
                      placeholder="Описание"
                      rows="2"
                      size="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col> -->

                <!-- submit button -->
                <b-col md="12">
                  <b-button
                    class="w-100 mt-3"
                    variant="primary"
                    type="submit"
                    @click.prevent="edit"
                    @disabled="addStatus"
                  >
                    Создать
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-form>
        </b-card>
    </b-tabs>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BFormCheckbox,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BRow,
  BTab,
  BTable,
  BTabs,
  BSpinner,
} from 'bootstrap-vue'
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from '@axios'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line no-unused-vars

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import { codeBubble } from '../forms/quill-editor/code'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BButton,
    BFormTextarea,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BCard,
    BTabs,
    BFormCheckbox,
    BTab,
    vSelect,
    BTable,
    quillEditor,
    BCardCode,
    BSpinner,

  },
  data() {
    return {
      required,
      codeBubble,
      editorOption: {
        theme: 'bubble',
        modules: {
          toolbar: [
            ['bold', 'italic'],
            ['link', 'image'],
          ],
        },
      },
      show: true,
      uploadFieldName: 'Загрузка файлов',
      fields: ['id',
        { key: 'type.name', label: 'Тип атрибута' },
        { key: 'name', label: 'Значения' },
        { key: 'quantity', label: 'Количество' },
        { key: 'price', label: 'Цена' },
        { key: 'actions', label: 'Действия' }],
      images: [],
      errors: [],
      preview: '',
      news: {
        title: '',
        description: '',
        photo: '',
        id: '',
        product_ids: '',
      },
    }
  },
  computed: {},
  async mounted() {
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleEdit()
    },
    removeImage() {
      this.news.photo = ''
    },
    removeImages(id) {
      this.images.splice(id, 1)
    },
    async edit() {
      const formData = new FormData()
      formData.append('title', this.news.title)
      formData.append('description', this.news.description)
      if (typeof this.news.photo !== 'string') {
        formData.append('photo', this.news.photo)
      }

      const ids = this.news.product_ids.split(', ').map(Number).filter((id) => !Number.isNaN(id)).filter(Boolean)

      ids.forEach((id) => {
        formData.append('product_ids[]', id)
      })

      await axios.post(`${$themeConfig.app.API}v2/admin/news`, formData,
        {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }).then(() => {
        this.addStatus = true
        this.ajaxRequest = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Успешно',
            text: 'Запись изменена',
            icon: 'CheckSquareIcon',
            variant: 'success',
          },
        })
        this.$router.push(`/news`)
      }).catch(e => {
        this.addStatus = false
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            text: e.message,
            icon: 'CheckSquareIcon',
            variant: 'dander',
          },
        })
      })
    },
    previewImage(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.preview = e.target.result
          this.news.photo = input.files[0]
        }
        reader.readAsDataURL(input.files[0])
      }
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dropbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
  /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.showIcon {
  display: block;
  position: relative;
}

.showIcon:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('https://img.icons8.com/material-outlined/50/trash--v1.png') no-repeat center center;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease;
}

.showIcon:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .8);
  opacity: 0;
  transition: opacity 0.4s linear;
}

.showIcon:hover:before,
.showIcon:hover:after {
  opacity: 1;
}
</style>
